<div om-layout="vertical">
  <div om-layout
       om-layout-fill
       om-layout-align="space-around center"
       class="container">
    <div om-layout='vertical'
         class="info">
      <div>
        <span class="summary-title"><i class="om-icon icon-magic"></i> Visit Summary</span>
        <span class="expiration">{{expiration}}</span>
      </div>
      <span class="helper-text">Learn more about HealthScribe</span>
    </div>
    <div>
      <a class="view-transcript-link"
         [href]="transcriptURL"
         data-testid="transcript-link"
         (click)="viewTranscript()"
         target="_healthscribe">View Transcript <i class="pi pi-external-link"></i></a>
    </div>
    <div>
      <omg-icon-button icon="gear"
                       library="fa"
                       (click)="openSettings()"
                       data-testid="settings-button"></omg-icon-button>
    </div>
    <div>
      <omg-icon-button icon="close"
                       library="fa"
                       (click)="closeTranscript($event)"
                       data-testid="close-button"></omg-icon-button>
    </div>
  </div>
  <div class="feedback-form-container">
    <omg-healthscribe-transcript-feedback-form (trackClose)="feedbackFormClosed($event)"
                                               (trackOpen)="feedbackFormOpened($event)"
                                               (submit)="submitFeedbackForm($event)">
    </omg-healthscribe-transcript-feedback-form>
  </div>
  <div class="transcript-sections">
    @for(section of transcriptSections; track section.id) {
    <omg-healthscribe-transcript-section [header]="section.header"
                                         [body]="section.body"
                                         (copied)="sectionCopied($event)">
    </omg-healthscribe-transcript-section>
    }
  </div>
</div>
