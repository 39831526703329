<div>
  <omg-healthscribe-banner-v1 *ngIf="showHealthscribeBanner(summary) === 'v1' && summary.appointment?.id"
                              [appointmentId]="summary.appointment!.id" />

  <div class="healthscribe-v2-container padding-normal"
       *ngIf="showHealthscribeBanner(summary) === 'v2' && summary.appointment?.id && !appointmentRecordingIsLoading">
    <omg-healthscribe-banner-v2 *ngIf="bannerState() !== 'recording'"
                                [variant]="bannerVariant"
                                (startRecording)="startHealthScribeRecording()"
                                (dismiss)="dismissHealthScribe()"
                                (undoDismiss)="undoDismissHealthScribe()"
                                (viewSummary)="viewSummary.emit($event)" />
    <omg-recording-manager mode="interactive"
                           [appointmentId]="summary.appointment.id.toString()"
                           *ngIf="bannerState() === 'recording' && summary.appointment?.id"></omg-recording-manager>
  </div>
</div>
